import React, { FC, useEffect } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { useDispatch } from "react-redux";
import { auth } from "../../firebase";
import { logout } from "../../features/userSlice";
import { getFirebaseUserEmail } from "../../firebase";
import { getFirestoreDocData } from "../../apis/firestoreAction";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    title: {
      flexGrow: 1,
      color: "#3e3e3e",
    },
    headerTitle: {
      backgroundColor: "#ffffff",
    },
    logoutButton: {
      width: "100px",
      height: "40px",
      right: "50px",
      fontSize: "16px",
      color: "#fff",
      backgroundColor: "#00CC99",
      "&:hover": {
        background: "#00CC99",
      },
    },
    dial: {
      fontSize: "12px",
      color: "#333333",
      fontWeight: 400,
      padding: "2px 0 0 10px"
    },
    description: {
      fontSize: "12px",
      color: "#ff4500",
      fontWeight: 900,
      padding: "2px 0 5px 10px"
    },
  })
);

const ButtonAppBar: FC<{
  titleName: string
  mailAddress?: string;
  employeeName?: string;
  hqDepartmentNameOA?: string;
  departmentNameOA?: string;
  jobCategoryNameOA?: string;
}> = ({
  titleName,
  mailAddress,
  employeeName,
  hqDepartmentNameOA,
  departmentNameOA,
  jobCategoryNameOA
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isDisplayButton = () => {
    return process.env.REACT_APP_MODE === "dev-mail-auth" || process.env.REACT_APP_MODE === "test-mail-auth" 
  }
  const [nowTime, setNowTime] = React.useState("");

  useEffect(() => {
    showClock();
    // 1秒毎に更新
    setInterval(() => {
      showClock();
    }, 1000);
  }, []);

  const signOut = () => {
    auth
      .signOut()
      .then(() => {
        dispatch(logout());
      })
      .catch((error) => {
        console.log(`logout error (${error})`);
      });
  }; 

  /**
   * 0埋め2桁処理
   * @param num 
   * @returns 
   */
  function twoDigit(num: number) {
    let ret;
    if (num < 10)
      ret = "0" + num;
    else
      ret = num;
    return ret;
  }

  /**
   * 時計描画処理
   */
  function showClock() {
    let nowDate = new Date();
    let nowYear = twoDigit(nowDate.getFullYear());
    let nowMonth = twoDigit(nowDate.getMonth() + 1);
    let nowDay = twoDigit(nowDate.getDate());
    let nowHour = twoDigit(nowDate.getHours());
    let nowMin = twoDigit(nowDate.getMinutes());
    let nowSec = twoDigit(nowDate.getSeconds());
    setNowTime(nowYear + "/" + nowMonth + "/" + nowDay + " " + nowHour + ":" + nowMin + ":" + nowSec);
  }

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.headerTitle}>
        <Toolbar>
          <img
            src={`${window.location.origin}/image/sejlogo.png`}
            alt="SEJアイコン"
            style={{ height: "7vh" }}
          />
          <Typography variant="h6" className={classes.title}>
            {titleName}
          </Typography>
          <div>
            <div>
              <Typography className={classes.dial}>
                {nowTime} {hqDepartmentNameOA} {departmentNameOA} {jobCategoryNameOA}
              </Typography>
            </div>
            <div>
              <Typography className={classes.dial}>
              {employeeName} {mailAddress}
              </Typography>
            </div>
            <div>
              <Typography className={classes.description}>
                右側の別タブアイコンを押下すると別タブで開かれます
              </Typography>
            </div>
          </div>
          <Button
            className={classes.logoutButton} 
            style={{ display: isDisplayButton() ? "" : "none" }} 
            onClick={() => {signOut();}}
          >
            ログオフ
          </Button>
        </Toolbar>
      </AppBar>
    </div>
  );
};
export default ButtonAppBar;
